import axios from "axios";
import { Call } from "../../../domain/call/call";

import { Configuration, CallApi, CallCallResponse, LeadgenStatus, CallCallsReportResponse } from "../generated/callApi";
import { CallInterface } from "../../../useCase/call/adapters/interface";

export class CallsRepository implements CallInterface {
    private service: CallApi;

    constructor() {
        this.service = new CallApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadCallsFiltred(offset: number, limit: number, from: Date, to: Date, entityId?: string , taskName?: string[] , callType?: string , operatorName?: string[], pipelineId?:string, leadStage?: string): Promise<{ count: number, list: Call[] } | Error> {
        try {
            let response = await this.service.apiCallGet(offset, limit, formatDate(from), formatDate(to), entityId, taskName, callType, operatorName, pipelineId, leadStage, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let calls: Call[] = []
                response.data.list!.forEach(callData => {

                    let call = this.toDomainCall(callData)
                    calls.push(call)
                });
                return {count: response.data.count!, list: calls}
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async GetAudioLocalLink(url: string): Promise<string | Error> {
        try {
            let response = await this.service.apiCallLinkGet(url, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data.link!
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async GetStatusesList(): Promise<LeadgenStatus[] | Error> {
        try {
            let response = await this.service.apiCallStatusesGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async GetOperatorsList(): Promise<string[] | Error> {
        try {
            let response = await this.service.apiCallOperatorsGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async GetTasksList(): Promise<string[] | Error> {
        try {
            let response = await this.service.apiCallTasksGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async DownloadXlsx(from: Date, to: Date, entityId?: string , taskName?: string[], callType?: string , operatorName?: string[], pipelineId?: string, leadStage?: string): Promise<CallCallsReportResponse | Error> {
        try {
            let response = await this.service.apiCallXlsxGet(formatDate(from), formatDate(to), entityId, taskName, callType, operatorName, pipelineId, leadStage, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    toDomainCall = (call: CallCallResponse) => {
        return new Call(call.id!, call.communication_id!, new Date(call.call_date!), call.entity_id!, call.task_name!, call.comment!, call.call_type!, call.call_length!, call.operator_name!, call.pipeline_id!, call.lead_stage!, call.region!, call.call_link!, new Date(call.created_at!), new Date(call.modified_at!))
    }

    
}

const formatDate = (day: Date) => {
    if (day.getFullYear() === 1) {
        return ""
    }
    const yyyy = day.getFullYear();
    let mm = (day.getMonth() + 1).toString(); // Months start at 0!
    let dd = day.getDate().toString();

    if (dd.length == 1) dd = '0' + dd;
    if (mm.length == 1) mm = '0' + mm;

    return dd + '.' + mm + '.' + yyyy.toString();
}